/** Defines the method for making data available in the partition. */
export var XmlaPartitionModeType;
(function (XmlaPartitionModeType) {
    /** Data will be imported from a data source. */
    XmlaPartitionModeType[XmlaPartitionModeType["Import"] = 0] = "Import";
    /** Data will be queried dynamically from a data source. */
    XmlaPartitionModeType[XmlaPartitionModeType["DirectQuery"] = 1] = "DirectQuery";
})(XmlaPartitionModeType || (XmlaPartitionModeType = {}));
/** An enumeration of possible values for defining cardinality on either side of a table relationship. */
export var XmlaRelationshipCardinality;
(function (XmlaRelationshipCardinality) {
    /** The relationship is unspecified. */
    XmlaRelationshipCardinality[XmlaRelationshipCardinality["None"] = 0] = "None";
    /** Specifies the 'one' side of a one-to-one or one-to-many relationship. */
    XmlaRelationshipCardinality[XmlaRelationshipCardinality["One"] = 1] = "One";
    /** Specifies the 'many' side of a one-to-many relationship. */
    XmlaRelationshipCardinality[XmlaRelationshipCardinality["Many"] = 2] = "Many";
})(XmlaRelationshipCardinality || (XmlaRelationshipCardinality = {}));
/**  The type of relationship. Currently, the only possible value is SingleColumn. */
export var XmlaRelationshipType;
(function (XmlaRelationshipType) {
    /** Relationships are constructed using a column-to-column mapping. */
    XmlaRelationshipType[XmlaRelationshipType["SingleColumn"] = 1] = "SingleColumn";
})(XmlaRelationshipType || (XmlaRelationshipType = {}));
/** When joining two date time columns, indicates whether to join on date and time parts or on date part only. */
export var XmlaRelatoinshipDateTimeRelationshipBehavior;
(function (XmlaRelatoinshipDateTimeRelationshipBehavior) {
    /** When joining two date time columns, join on both the date and time parts. */
    XmlaRelatoinshipDateTimeRelationshipBehavior[XmlaRelatoinshipDateTimeRelationshipBehavior["DateAndTime"] = 1] = "DateAndTime";
    /** When joining two date time columns, join on date part only. */
    XmlaRelatoinshipDateTimeRelationshipBehavior[XmlaRelatoinshipDateTimeRelationshipBehavior["DatePartOnly"] = 2] = "DatePartOnly";
})(XmlaRelatoinshipDateTimeRelationshipBehavior || (XmlaRelatoinshipDateTimeRelationshipBehavior = {}));
/** Indicates how relationships influence filtering of data. The enumeration defines the possible behaviors. */
export var XmlaRelationshipCrossFilteringBehavior;
(function (XmlaRelationshipCrossFilteringBehavior) {
    /** The rows selected in the 'To' end of the relationship will automatically filter scans of the table in the 'From' end of the relationship. */
    XmlaRelationshipCrossFilteringBehavior[XmlaRelationshipCrossFilteringBehavior["OneDirection"] = 1] = "OneDirection";
    /** Filters on either end of the relationship will automatically filter the other table. */
    XmlaRelationshipCrossFilteringBehavior[XmlaRelationshipCrossFilteringBehavior["BothDirections"] = 2] = "BothDirections";
    /** The engine will analyze the relationships and choose one of the behaviors by using heuristics. */
    XmlaRelationshipCrossFilteringBehavior[XmlaRelationshipCrossFilteringBehavior["Automatic"] = 3] = "Automatic";
})(XmlaRelationshipCrossFilteringBehavior || (XmlaRelationshipCrossFilteringBehavior = {}));
export var XmlaPartitionSourceType;
(function (XmlaPartitionSourceType) {
    XmlaPartitionSourceType[XmlaPartitionSourceType["Unsupported"] = -1] = "Unsupported";
    XmlaPartitionSourceType[XmlaPartitionSourceType["Query"] = 0] = "Query";
    XmlaPartitionSourceType[XmlaPartitionSourceType["M"] = 1] = "M";
    XmlaPartitionSourceType[XmlaPartitionSourceType["Calculated"] = 2] = "Calculated";
    XmlaPartitionSourceType[XmlaPartitionSourceType["PolicyRange"] = 3] = "PolicyRange";
})(XmlaPartitionSourceType || (XmlaPartitionSourceType = {}));
export var XmlaColumnType;
(function (XmlaColumnType) {
    XmlaColumnType[XmlaColumnType["Unsupported"] = -1] = "Unsupported";
    XmlaColumnType[XmlaColumnType["DataColumn"] = 0] = "DataColumn";
    XmlaColumnType[XmlaColumnType["CalculatedColumn"] = 1] = "CalculatedColumn";
    XmlaColumnType[XmlaColumnType["CalculatedTableColumn"] = 2] = "CalculatedTableColumn";
})(XmlaColumnType || (XmlaColumnType = {}));
/** Specifies the granularity of the refresh policy for auto partitioning. */
export var RefreshGranularityType;
(function (RefreshGranularityType) {
    /** Invalid Granularity. */
    RefreshGranularityType[RefreshGranularityType["Invalid"] = -1] = "Invalid";
    /** Granularity of a day. */
    RefreshGranularityType[RefreshGranularityType["Day"] = 0] = "Day";
    /** Granularity of a month. */
    RefreshGranularityType[RefreshGranularityType["Month"] = 1] = "Month";
    /** Granularity of a quarter. */
    RefreshGranularityType[RefreshGranularityType["Quarter"] = 2] = "Quarter";
    /** Granularity of a year. */
    RefreshGranularityType[RefreshGranularityType["Year"] = 3] = "Year";
})(RefreshGranularityType || (RefreshGranularityType = {}));
/** The type of DataSource. */
export var DataSourceType;
(function (DataSourceType) {
    /** A data source having a data provider and connection string. */
    DataSourceType[DataSourceType["Provider"] = 1] = "Provider";
})(DataSourceType || (DataSourceType = {}));
/** Determines how credentials are obtained for an impersonated connection to a data
 * source during data import or refresh. */
export var ImpersonationMode;
(function (ImpersonationMode) {
    /** Uses the inherited value from the ImpersonationMode on the DataSourceImpersonationInfo
     * object in the database. */
    ImpersonationMode[ImpersonationMode["Default"] = 1] = "Default";
    /** A Windows user account having read permissions on the external data source. */
    ImpersonationMode[ImpersonationMode["ImpersonateAccount"] = 2] = "ImpersonateAccount";
    /** Not supported. */
    ImpersonationMode[ImpersonationMode["ImpersonateAnonymous"] = 3] = "ImpersonateAnonymous";
    /** Not supported for tabular model databases attached to an Analysis Services instance. */
    ImpersonationMode[ImpersonationMode["ImpersonateCurrentUser"] = 4] = "ImpersonateCurrentUser";
    /** Startup account of the Analysis Services instance. This account must have read
     * permissions on a data source to enable data refresh. */
    ImpersonationMode[ImpersonationMode["ImpersonateServiceAccount"] = 5] = "ImpersonateServiceAccount";
    /** Do not reference this member directly in your code. It supports the Analysis
     * Services infrastructure. */
    ImpersonationMode[ImpersonationMode["ImpersonateUnattendedAccount"] = 6] = "ImpersonateUnattendedAccount";
})(ImpersonationMode || (ImpersonationMode = {}));
/** Controls the locking behavior of the SQL statements when executing commands against
 * the data source. */
export var DatasourceIsolation;
(function (DatasourceIsolation) {
    /** Specifies that statements cannot read data that has been modified, but not committed,
     * by other transactions. This prevents dirty reads. */
    DatasourceIsolation[DatasourceIsolation["ReadCommitted"] = 1] = "ReadCommitted";
    /** Specifies that the data read by any statement in a transaction is transactionally
     * consistent, as if the statements in a transaction receive a snapshot of the committed
     * data as it existed at the start of the transaction. */
    DatasourceIsolation[DatasourceIsolation["Snapshot"] = 2] = "Snapshot";
})(DatasourceIsolation || (DatasourceIsolation = {}));
/** Indicates the dialect of the query expression. */
export var ExpressionKind;
(function (ExpressionKind) {
    /** The expression is based on the M dialect. */
    ExpressionKind[ExpressionKind["M"] = 0] = "M";
})(ExpressionKind || (ExpressionKind = {}));
