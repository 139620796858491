function compact(array) {
    return array.filter(i => !!i);
}
/**
 * Static list of hard-coded fields for every list.
 */
export const DefaultFields = (list) => !list.ExcludeDefaultFields
    ? compact([
        {
            Title: 'Created By',
            ColumnName: 'author_id',
            FieldType: 'User',
            SystemManaged: true,
            Updateable: false,
            Searchable: true,
        },
        {
            Title: 'Created',
            ColumnName: 'created',
            FieldType: 'DateTime',
            SystemManaged: true,
            Updateable: false,
        },
        {
            Title: 'Modified By',
            ColumnName: 'editor_id',
            FieldType: 'User',
            SystemManaged: true,
            Updateable: false,
            Searchable: true,
        },
        {
            Title: 'Modified',
            ColumnName: 'modified',
            FieldType: 'DateTime',
            SystemManaged: true,
            Updateable: false,
            Searchable: true,
        },
        {
            Title: 'Parent',
            ColumnName: 'parent_id',
            FieldType: 'Lookup',
            SystemManaged: true,
            Updateable: true,
            AlwaysSelect: true,
            Settings: {
                LookupList: list.Extends || list.ListName,
            },
        },
        {
            Title: 'Drive ID',
            ColumnName: 'drive_id',
            FieldType: 'Text',
            SystemManaged: true,
            Hidden: true,
            Updateable: true,
            AlwaysSelect: true,
            Settings: {
                Visible: false,
            },
        },
        {
            Title: 'Folder ID',
            ColumnName: 'folder_id',
            FieldType: 'Text',
            SystemManaged: true,
            Hidden: true,
            AlwaysSelect: true,
            Updateable: true,
            Settings: {
                Visible: false,
            },
        },
        {
            Title: 'Sorting Rank',
            ColumnName: 'rank',
            FieldType: 'Text',
            SystemManaged: true,
            Hidden: true,
            Updateable: true,
            Settings: {
                Visible: false,
            },
        },
        {
            Title: 'Embedded Files',
            ColumnName: 'embedded_files',
            FieldType: 'JSON',
            SystemManaged: true,
            Nullable: true,
            Hidden: true,
            DefaultValue: '[]',
        },
        {
            Title: 'External ID',
            ColumnName: 'sys_external_id',
            FieldType: 'Text',
            SystemManaged: true,
            Nullable: true,
            Hidden: true,
        },
        list.ListName !== 'Tasks' &&
            list.Extends !== 'Tasks' && {
            Title: 'Linked Tasks',
            ColumnName: 'sys_linked_tasks',
            FieldType: 'Subquery',
            SystemManaged: true,
            Nullable: true,
            // Hidden: true,
            Settings: {
                DisplayAs: 'JSON',
                Query: `
          (select
            TSK.id,
            TSK.title,
            TSK.start_date,
            TSK.due_date,
            TSK.sys_type_id,
            TSK.status,
            TSK.parent_id,
            parent.sys_type_id as [parent$type],
             (
                select ul2.*
                from universal_link ul2
                where ul2.task_id = TSK.id
                for json path
            ) as LinkedItems,
            (
              select dependency.*
              from [dependency]
              where [dependency].[source_id] = [TSK].[id]
              for json path
            ) as dependencies,
            entity_type.type as [__metadata.type]
            from [task] TSK
              join universal_link ul on ul.[${list.Table}_id] = T.id and ul.task_id = TSK.id
              join entity_type on TSK.sys_type_id = entity_type.id
              left join [task] [parent] on [parent].[id] = [TSK].[parent_id]
            where [TSK].[deleted] is null
              for json path)`,
            },
        },
    ])
    : [];
