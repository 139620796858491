import { ColumnType } from './column-type';
import { NumberFormat } from './number-format';
/**
 * Describes the data type of a dynamic field.
 */
const fieldTypes = {
    Text: {
        Id: 'Text',
        Title: 'Single line of Text',
        ColumnType: ColumnType.Text,
        VisibleOnOptions: true,
        ToolboxComponentType: 'TextField',
        OpenAPIType: {
            type: 'string',
        },
        Searchable: true,
    },
    Email: {
        Id: 'Email',
        Title: 'Email',
        ColumnType: ColumnType.Email,
        VisibleOnOptions: true,
        ToolboxComponentType: 'EmailField',
        OpenAPIType: {
            type: 'string',
        },
        Searchable: true,
    },
    Note: {
        Id: 'Note',
        Title: 'Multiple lines of Text',
        ColumnType: ColumnType.Note,
        VisibleOnOptions: true,
        ToolboxComponentType: 'NoteField',
        DefaultHeight: 3,
        OpenAPIType: {
            type: 'string',
        },
        allowAsTitleField: false,
        Searchable: false,
    },
    DateTime: {
        Id: 'DateTime',
        Title: 'Date and Time',
        ColumnType: ColumnType.DateTime,
        VisibleOnOptions: true,
        ToolboxComponentType: 'DateField',
        OpenAPIType: {
            type: 'string',
            format: 'date-time',
        },
        Searchable: true,
    },
    Choice: {
        Id: 'Choice',
        Title: 'Choice',
        ColumnType: ColumnType.Text,
        VisibleOnOptions: true,
        ToolboxComponentType: 'ChoiceField',
        OpenAPIType: {
            type: 'string',
        },
        Searchable: true,
    },
    Lookup: {
        Id: 'Lookup',
        Title: 'Lookup',
        ColumnType: ColumnType.Lookup,
        VisibleOnOptions: true,
        allowAsTitleField: false,
        ToolboxComponentType: 'LookupField',
        OpenAPIType: {
            type: 'integer',
        },
        Searchable: true,
    },
    Boolean: {
        Id: 'Boolean',
        Title: 'Boolean',
        ColumnType: ColumnType.Boolean,
        VisibleOnOptions: true,
        ToolboxComponentType: 'BooleanField',
        OpenAPIType: {
            type: 'boolean',
        },
        Searchable: true,
    },
    Number: {
        Id: 'Number',
        Title: 'Number',
        ColumnType: ColumnType.Number,
        VisibleOnOptions: true,
        ToolboxComponentType: 'NumberField',
        OpenAPIType: {
            type: 'number',
        },
        Searchable: true,
    },
    Percentage: {
        Id: 'Percentage',
        Title: 'Percentage',
        ColumnType: ColumnType.Number,
        VisibleOnOptions: true,
        ToolboxComponentType: 'PercentageField',
        DefaultSettings: {
            NumberFormat: NumberFormat.Percentage,
        },
        OpenAPIType: {
            type: 'number',
        },
        Searchable: true,
    },
    Currency: {
        Id: 'Currency',
        Title: 'Currency',
        ColumnType: ColumnType.Number,
        VisibleOnOptions: true,
        ToolboxComponentType: 'CurrencyField',
        DefaultSettings: {
            NumberFormat: NumberFormat.Currency,
        },
        OpenAPIType: {
            type: 'number',
        },
        Searchable: true,
    },
    Computed: {
        Id: 'Computed',
        Title: 'Computed',
        ColumnType: ColumnType.Computed,
        VisibleOnOptions: true,
        ToolboxComponentType: 'ReadOnlyField',
        OpenAPIType: {
            type: 'object',
        },
        Searchable: true,
    },
    User: {
        Id: 'User',
        Title: 'User',
        ColumnType: ColumnType.User,
        VisibleOnOptions: true,
        allowAsTitleField: false,
        ToolboxComponentType: 'UserField',
        OpenAPIType: {
            type: 'integer',
        },
        Searchable: true,
    },
    JSON: {
        Id: 'JSON',
        Title: 'JSON',
        ColumnType: ColumnType.JSON,
        VisibleOnOptions: false,
        OpenAPIType: {
            type: 'object',
        },
        ToolboxComponentType: 'DataField',
        allowAsTitleField: false,
        Searchable: true,
    },
    Subquery: {
        Id: 'Subquery',
        Title: 'Subquery',
        ColumnType: ColumnType.Raw,
        VisibleOnOptions: true,
        ToolboxComponentType: 'ReadOnlyField',
        DefaultSettings: {},
        OpenAPIType: {
            type: 'object',
        },
        Searchable: true,
    },
    Integer: {
        Id: 'Integer',
        Title: 'Whole number',
        ColumnType: ColumnType.Integer,
        VisibleOnOptions: true,
        ToolboxComponentType: 'NumberField',
        OpenAPIType: {
            type: 'integer',
        },
        Searchable: true,
    },
    GUID: {
        Id: 'GUID',
        Title: 'Globally Unique Identifier',
        ColumnType: ColumnType.GUID,
        VisibleOnOptions: false,
        ToolboxComponentType: 'TextField',
        OpenAPIType: {
            type: 'string',
            format: 'uuid',
        },
        Searchable: true,
    },
    XML: {
        Id: 'XML',
        Title: 'XML Data',
        ColumnType: ColumnType.XML,
        VisibleOnOptions: false,
        ToolboxComponentType: 'FileField',
        OpenAPIType: {
            type: 'string',
            format: 'uuid',
        },
        Searchable: true,
    },
    MultiLookup: {
        Id: 'MultiLookup',
        Title: 'Multiple Lookups',
        ColumnType: ColumnType.None,
        VisibleOnOptions: true,
        ToolboxComponentType: 'MultiLookupField',
        Convertible: false,
        OpenAPIType: {
            type: 'object',
        },
        allowAsTitleField: false,
        Searchable: false,
    },
    MultiChoice: {
        Id: 'MultiChoice',
        Title: 'Multiple Choices',
        ColumnType: ColumnType.None,
        VisibleOnOptions: false,
        Convertible: false,
        ToolboxComponentType: 'ChoiceField',
        OpenAPIType: {
            type: 'object',
        },
        allowAsTitleField: false,
        Searchable: true,
    },
    Report: {
        Id: 'Report',
        Title: 'PowerBI Report',
        ColumnType: ColumnType.Text,
        VisibleOnOptions: true,
        ToolboxComponentType: 'TextField',
        OpenAPIType: {
            type: 'string',
        },
        allowAsTitleField: false,
        Searchable: true,
    },
};
export const FieldTypes = fieldTypes;
