export var ColumnType;
(function (ColumnType) {
    ColumnType[ColumnType["None"] = 0] = "None";
    ColumnType[ColumnType["Text"] = 2] = "Text";
    ColumnType[ColumnType["Note"] = 3] = "Note";
    ColumnType[ColumnType["DateTime"] = 4] = "DateTime";
    ColumnType[ColumnType["Lookup"] = 7] = "Lookup";
    ColumnType[ColumnType["Boolean"] = 8] = "Boolean";
    ColumnType[ColumnType["Number"] = 9] = "Number";
    ColumnType[ColumnType["Currency"] = 10] = "Currency";
    ColumnType[ColumnType["Computed"] = 12] = "Computed";
    ColumnType[ColumnType["File"] = 18] = "File";
    ColumnType[ColumnType["User"] = 20] = "User";
    ColumnType[ColumnType["PrivateUsers"] = 100] = "PrivateUsers";
    ColumnType[ColumnType["Users"] = 102] = "Users";
    ColumnType[ColumnType["Outcome"] = 103] = "Outcome";
    ColumnType[ColumnType["GUID"] = 104] = "GUID";
    ColumnType[ColumnType["JSON"] = 105] = "JSON";
    ColumnType[ColumnType["Raw"] = 106] = "Raw";
    ColumnType[ColumnType["Integer"] = 107] = "Integer";
    ColumnType[ColumnType["Email"] = 108] = "Email";
    ColumnType[ColumnType["XML"] = 109] = "XML";
})(ColumnType || (ColumnType = {}));
