export var EncryptionStatus;
(function (EncryptionStatus) {
    /** Encryption is supported and is in sync with the encryption settings */
    EncryptionStatus["InSyncWithWorkspace"] = "InSyncWithWorkspace";
    /** Encryption is supported and not in sync with the encryption settings */
    EncryptionStatus["NotInSyncWithWorkspace"] = "NotInSyncWithWorkspace";
    /** Encryption is not supported for this dataset */
    EncryptionStatus["NotSupported"] = "NotSupported";
    /** Unable to determine state due to dataset corruption */
    EncryptionStatus["Unknown"] = "Unknown";
})(EncryptionStatus || (EncryptionStatus = {}));
