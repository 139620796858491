/**
 * Types for legacy webhooks.
 *
 * @deprecated This enum is for master database hooks and is no
 * longer in use as of v1.15, see the Webhook system list
 * instead
 */
export var WebhookType;
(function (WebhookType) {
    WebhookType[WebhookType["ItemUpdated"] = 0] = "ItemUpdated";
    WebhookType[WebhookType["ItemCreated"] = 1] = "ItemCreated";
    WebhookType[WebhookType["EntityCreated"] = 2] = "EntityCreated";
    WebhookType[WebhookType["ItemDeleted"] = 3] = "ItemDeleted";
})(WebhookType || (WebhookType = {}));
