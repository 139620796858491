/** The permission mode flags for an object. */
export var ModeFlags;
(function (ModeFlags) {
    /** Principal may read the object */
    ModeFlags[ModeFlags["Read"] = 1] = "Read";
    /** Principal may write the object */
    ModeFlags[ModeFlags["Write"] = 2] = "Write";
    /** Principal may delete the object */
    ModeFlags[ModeFlags["Delete"] = 4] = "Delete";
})(ModeFlags || (ModeFlags = {}));
